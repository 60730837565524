import React, { Fragment, useEffect, useState, useContext } from "react";
import {
  Animate,
  AuthContext,
  Row,
  useAPI,
  Loader,
  Card,
  Grid,
  Image,
  Button,
} from "components/lib";
import { useParams } from "react-router-dom";
import Axios from "axios";
import { SubNav } from "components/lib";
import jsPDF from "jspdf";
import logo from "../../assets/IdentityCheck_Logo_RGB_Purple.png";

export function VerificationView(props) {
  const params = useParams();
  const [imageDataFetched, setImageDataFetched] = useState(false);
  const [imagesResponse, setImgagesResponse] = useState([]);
  const [navBarItems, setNavBarItems] = useState([]);

  const verification = useAPI(`/api/verification/${params.id}`);
  const accountInfo = useAPI(`/api/account`);
  const authContext = useContext(AuthContext);

  let images = [];
  useEffect(() => {
    async function getData() {
      verification.data.images.forEach(async function (image) {
        const mResponse = await Axios.get(`/api/veriff/media/${image.id}`);

        images.push({...mResponse.data?.data, name: image.name, id: image.id});
        setImgagesResponse(images);
        if (images.length === verification.data.images.length) {
          setImageDataFetched(true);
        }
      });
    }

    async function setUpNavBarItems() {
      const verificationInfo = verification.data.verification[0];
      console.log("Setting up nav bar items", verificationInfo);
      let updatedNavBarItems = [];

      if (verificationInfo.sanctions_results) {
        updatedNavBarItems.push(
          {
            label: "KYC",
            link: `/verification/${params.id}`,
          },
          {
            label: "Sanctions",
            link: `/verification/sanctions/${params.id}`,
          }
        );
      }

      if (verificationInfo.adverse_media_results) {
        updatedNavBarItems.push({
          label: "Adverse Media",
          link: `/verification/adverse/${params.id}`,
        });

        if (updatedNavBarItems.length === 1) {
          updatedNavBarItems = [
            {
              label: "KYC",
              link: `/verification/${params.id}`,
            },
            ...updatedNavBarItems,
          ];
        }
      }

      if (verificationInfo.response_id) {
        updatedNavBarItems.push({
          label: "Response",
          link: `/verification/${params.id}/response/${verificationInfo.response_id}`,
        });
      }

      if (verificationInfo?.additional_checks?.length > 0) {
        updatedNavBarItems.push({
          label: "US Criminal Checks",
          link: `/verification/us-criminal-v1/${params.id}`,
        });
      }

      setNavBarItems(updatedNavBarItems);
    }

    if (!verification.loading && verification.data) {
      getData();
      setUpNavBarItems();
    }
  }, [verification]);

  function downloadPDF() {
    console.log("Download PDF!");
    var doc = new jsPDF("portrait", "px", "a4", "false");
    const pageHeight = doc.internal.pageSize.getHeight();
    const pageWidth = doc.internal.pageSize.getWidth();

    doc.addImage(logo, "PNG", 310, 30, 100, 15);
    doc.setFont("helvetica", "bold");
    doc.setFontSize(16);
    doc.text(50, 40, `Identity Verification Report`);
    doc.setFontSize(9);
    doc.setFont("helvetica", "normal");
    doc.text(
      50,
      50,
      `Session: ${verification?.data?.verification?.[0].session_id}`
    );
    doc.setFontSize(13);
    doc.setFont("helvetica", "bold");
    doc.text(50, 90, "Report Summary");
    doc.setFontSize(12);
    doc.text(50, 110, "Identity");
    doc.setFont("helvetica", "normal");
    doc.text(50, 125, "First Name");
    doc.text(170, 125, `${verification?.data?.verification?.[0]?.result_info?.verification?.person
      ?.firstName || "No Name"}`);
    doc.text(50, 140, "Last Name");
    doc.text(170, 140, `${verification?.data?.verification?.[0]?.result_info?.verification?.person
      ?.lastName || "No Name"}`);
    doc.text(50, 155, `Date of Birth`);
    doc.text(
      170,
      155,
      `${
        // verification?.data?.verification?.[0].result_info?.verification
        //   ?.document?.type || "No Document"
        verification?.data?.verification?.[0].result_info?.verification?.person
          ?.dateOfBirth || "No DOB"
      }`
    );
    doc.setFont("helvetica", "bold");
    doc.text(50, 180, "Verification Result");
    doc.setFont("helvetica", "normal");
    doc.text(50, 195, "Status");
    doc.text(
      170,
      195,
      `${verification?.data?.verification?.[0].result_info?.verification?.status}` ||
        "No Status"
    );

    doc.text(50, 210, "Timestamp");

    doc.text(
      170,
      210,
      verification?.data?.verification?.[0].result_info?.verification
        ?.decisionTime || "NA"
    );

    doc.text(50, 225, "Service");
    doc.text(170, 225, "Veriff Biometric Face and Document Identity Check");

    doc.text(50, 240, "Liveness test");
    doc.text(170, 240, "Passed");

    doc.text(50, 255, "Data and Device integrity");
    doc.text(170, 255, "Passed");

    doc.setFont("helvetica", "bold");
    doc.text(50, 280, "Prepared for");
    doc.setFont("helvetica", "normal");

    doc.text(50, 295, "Business Name");
    doc.text(
      170,
      295,
      authContext?.user?.accounts?.filter(
        (a) => (a.id = authContext?.user?.account_id)
      )?.[0]?.name || "No Business"
    );
    doc.setFont("helvetica", "bold");
    doc.text(50, 320, "Document");
    doc.setFont("helvetica", "normal");
    doc.text(50, 335, "Type");
    doc.text(
      170,
      335,
      verification?.data?.verification?.[0].result_info?.verification?.document
        ?.type || "No Document"
    );
    doc.text(50, 350, "Country");
    doc.text(
      170,
      350,
      verification?.data?.verification?.[0].result_info?.verification?.document
        ?.country || "NA"
    );
    doc.text(50, 365, "Number");
    doc.text(
      170,
      365,
      verification?.data?.verification?.[0].result_info?.verification?.document
        ?.number || "No Document"
    );
    doc.text(50, 380, "Valid From");
    doc.text(
      170,
      380,
      verification?.data?.verification?.[0].result_info?.verification?.document
        ?.validFrom || "NA"
    );
    doc.text(50, 395, "Valid To");
    doc.text(
      170,
      395,
      verification?.data?.verification?.[0].result_info?.verification?.document
        ?.validUntil || "NA"
    );

    doc.setFont("helvetica", "bold");
    doc.text(50, 420, "Session");
    doc.setFont("helvetica", "normal");
    doc.text(50, 435, "IP Address");
    doc.text(
      170,
      435,
      verification?.data?.verification?.[0].result_info?.technicalData?.ip ||
        "No IP"
    );
    doc.text(50, 450, "IP Location");
    doc.text(
      170,
      450,
      verification?.data?.verification?.[0].result_info?.technicalData
        ?.location || "No Location"
    );
    doc.text(50, 465, "Longitude");
    doc.text(
      170,
      465,
      `${verification?.data?.verification?.[0].result_info?.technicalData?.longitude}` ||
        "No Longitude"
    );

    doc.text(50, 480, "Latitude");
    doc.text(
      170,
      480,
      `${verification?.data?.verification?.[0].result_info?.technicalData?.latitude}` ||
        "No Latitude"
    );

    // doc.text(50, 495, "OS");
    // doc.text(
    //   170,
    //   495,
    //   verification?.data?.verification?.[0].result_info?.technicalData?.os ||
    //     "No OS"
    // );

    // doc.text(50, 510, "Browser");
    // doc.text(
    //   170,
    //   510,
    //   verification?.data?.verification?.[0].result_info?.technicalData
    //     ?.browser || "No Browser"
    // );

    // doc.text(50, 525, "Device");
    // doc.text(
    //   170,
    //   525,
    //   verification?.data?.verification?.[0].result_info?.technicalData
    //     ?.device || "No Device"
    // );

    if (verification?.data?.verification?.[0].type === "kyc+sanctions") {
      if (
        verification?.data?.verification?.[0].sanctions_results?.found_records
          .length === 0
      ) {
        doc.setFont("helvetica", "bold");
        doc.text(50, 555, "Sanctions Results");
        doc.setFont("helvetica", "normal");
        doc.text(170, 555, "No Matches Found");
      } else {
        {
          verification?.data?.verification?.[0].sanctions_results.found_records.map(
            (r, i) => (
              <div key={i}>
                {doc.addPage()}
                {doc.setFont("helvetica", "bold")};
                {doc.text(50, 40, "Sanctions Results")};
                {doc.setFont("helvetica", "normal")};
                {doc.text(50, 80, "Last Names")}
                {doc.text(170, 80, r?.last_names?.toString())}
                {doc.text(50, 95, "Given Names")}
                {doc.text(170, 95, r?.given_names?.toString())}
                {doc.text(50, 110, "Alias Names")}
                {doc.text(170, 110, r?.alias_names?.toString())}
                {doc.text(50, 125, "DoB")}
                {doc.text(170, 125, r?.date_of_birth?.toString())}
                {doc.text(50, 140, "Place of Birth")}
                {doc.text(170, 140, r?.place_of_birth?.toString())}
                {doc.text(50, 155, "Name")}
                {doc.text(170, 155, r?.name)}
                {doc.text(50, 170, "Source")}
                {doc.text(170, 170, r?.source_id)}
              </div>
            )
          );
        }
      }
    }

    doc.setFont("Courier-Bold", "bold");
    {
      imagesResponse?.map((image, i) => {
        const widthRatio = (pageWidth - 80) / image.width;
        const heightRatio = (pageHeight - 100) / image.height;
        // console.log("width ratio", widthRatio);
        // console.log("height ratio", heightRatio);

        const ratio = widthRatio > heightRatio ? heightRatio : widthRatio;
        return (
          <div key={i}>
            {console.log("image", i, image.width, image.height)}
            {doc.addPage()}
            {doc.addImage(logo, "PNG", 310, 30, 100, 15)}
            {doc.setFont("helvetica", "bold")}
            {doc.setFontSize(16)}
            {doc.text(50, 40, `Identity Verification Report`)}
            {doc.setFontSize(9)}
            {doc.setFont("helvetica", "normal")}
            {doc.text(
              50,
              50,
              `Session: ${verification?.data?.verification?.[0].session_id}`
            )}
            {doc.setFontSize(12)}
            {doc.setFont("helvetica", "bold")}
            {doc.text(50, 75, image.name)}
            {doc.setFont("helvetica", "normal")}
            {doc.text(50, 90, "Date Supplied")}
            {doc.text(
              170,
              90,
              `${
                verification?.data?.verification?.[0].result_info?.verification
                  ?.acceptanceTime
                  ? verification?.data?.verification?.[0].result_info?.verification?.acceptanceTime.split(
                      "T"
                    )[0]
                  : "NA"
              }` || "NA"
            )}
            {doc.addImage(
              `data:image/png;base64,${image.image}`,
              "PNG",
              50,
              105,
              ratio * image.width,
              ratio * image.height
            )}
          </div>
        );
      });
    }

    doc.save(`Identity Verification Report`);
  }

  function getSanctionsResultHtml(sanctionsResults) {
    if (sanctionsResults.found_records.length === 0) {
      return <p>Sanctions Results: No matches found</p>;
    } else {
      const sanctionsMarkupArray = sanctionsResults.found_records.map((r) => (
        <p>
          Last Names: {r?.last_names?.toString()}
          <br />
          Given Names: {r?.given_names?.toString()}
          <br />
          Alias Names: {r?.alias_names?.toString()}
          <br />
          DoB: {r?.date_of_birth?.toString()}
          <br />
          Place of Birth: {r?.place_of_birth?.toString()}
          <br />
          Name: {r?.name}
          <br />
          Source: {r?.source_id}
          <br />
          <br />
        </p>
      ));

      const sanctionsMarkup = [];
      sanctionsMarkup.push(
        <div>
          <br />
          <p>
            Sanctions Results: <strong>Possible Matches found</strong>
          </p>
        </div>
      );

      sanctionsMarkupArray.forEach((sanction, index) => {
        sanctionsMarkup.push(<div key={index}>{sanction}</div>);
      });

      return sanctionsMarkup;
    }
  }

  return !imageDataFetched &&
    verification.loading &&
    verification.data === undefined &&
    imagesResponse.data === undefined &&
    images.length === 0 ? (
    <Loader></Loader>
  ) : (
    <Fragment>
      <Animate type="pop">
        <Row color="brand" title="Verification Result">
          {" "}
        </Row>

        <Card>
          {console.log("Full response: ", verification)}
          {console.log(verification.data?.images)}
          {console.log("imageDataFetched: ", imageDataFetched)}
          {console.log("imagesResponse: ", imagesResponse)}

          <Card
            title={`Verification for ${verification?.data?.verification?.[0].first_name} ${verification?.data?.verification?.[0].last_name}`}
          >
            <SubNav items={navBarItems} />

            <p>
              <Button
                icon="download"
                action={downloadPDF}
                text="Download PDF"
              />
            </p>
            <br />

            <p>
              Document Type:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.document?.type || "No Document"}
            </p>
            <p>Email: {verification?.data?.verification?.[0].email}</p>
            <p>
              Status:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.status || "No Status"}
            </p>
            <p>
              IP:{" "}
              {verification?.data?.verification?.[0].result_info?.technicalData
                ?.ip || "No IP"}
            </p>
            <p>
              Document First Name:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.person?.firstName || "No Name"}
            </p>
            <p>
              Document Last Name:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.person?.lastName || "No Name"}
            </p>
            <p>
              Document ID Number{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.person?.idNumber || "No ID Number"}
            </p>
            <p>
              Date Of Birth:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.person?.dateOfBirth || "No DOB"}
            </p>
            <p>
              Document Number:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.document?.number || "No Document"}
            </p>
            <p>
              Document Country:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.document?.country || "NA"}
            </p>
            <p>
              Valid From:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.document?.validFrom || "NA"}
            </p>
            <p>
              Valid Until:{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.document?.validUntil || "NA"}
            </p>
            <p>
              Decision Time{" "}
              {verification?.data?.verification?.[0].result_info?.verification
                ?.decisionTime || "NA"}
            </p>
            <br />
            {accountInfo?.data?.sanctions_settings?.address_extraction && (
              <>
                <h2>Address Extraction</h2>
                <p>
                  Parsed Address:{" "}
                  {verification?.data?.verification?.[0]?.result_info
                    ?.verification?.person?.addresses?.[0]?.fullAddress ||
                    "No Address Found"}
                </p>
              </>
            )}
            {console.log("FUll response: ", verification)}
            {console.log(verification.data?.images)}
            {console.log("imagesResponse: ", imagesResponse)}

            {imagesResponse === null ||
            verification.data === undefined ||
            imageDataFetched === false ? (
              <Animate type="pop">
                <Row color="white" loading={verification?.loading}>
                  <Card title="Images associated to your verifications will appear here" />
                </Row>
              </Animate>
            ) : (
              <div>
                {console.log("imagesResponse: ", imagesResponse)}
                {imagesResponse?.map((image, i) => (
                  <Card key={i}>
                    <Image
                      key={i}
                      source={`data:image/png;base64,${image.image}`}
                      title={verification.data.images[i].name}
                    />
                    <p>{image.name}</p>
                  </Card>
                ))}

                {/* <img src={`data:image/jpeg;base64,${mediaResponse.data?.image}`} /> */}
              </div>
            )}
          </Card>
        </Card>
      </Animate>
    </Fragment>
  );
}
