/***
 *
 *   DASHBOARD
 *   Template dashboard example demonstrating various components inside a view.
 *
 **********/

import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  Card,
  Chart,
  Table,
  Message,
  Grid,
  Button,
  Animate,
  useNavigate,
  Feedback,
  useAPI,
  ViewContext,
  Paginate,
} from "components/lib";
import axios from "axios";
import "./dashboard.scss";
import MaterialReactTable from "material-react-table";
import { Box } from "@mui/material";
import { mkConfig, download, generateCsv } from "export-to-csv";

export function Dashboard(props) {
  const [verifiCationData, setVerificationData] = useState([]);
  const [verificationChart, setVerificationChart] = useState({});
  // const stats = useAPI("/api/demo/stats");
  // const progress = useAPI("/api/demo/progress");
  // const table = useAPI("/api/demo/users/list");
  // const userChart = useAPI("/api/demo/users/types");
  // const revenueChart = useAPI("/api/demo/revenue");

  const [reRender, setReRender] = useState(1);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const verifications = useAPI("/api/verification");
  const context = useContext(ViewContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (verifications?.data?.verification?.length === 0) {
      return (window.location = "/landing");
    }
    chartData();
  }, [verifications]);

  const chartData = () => {
    let data = verifications?.data?.verification;
    let total = [];
    let positive = [];
    let negative = [];
    if (data) {
      total = data;
      positive = data?.filter((x) => x.result?.toLowerCase() === "positive");
      negative = data?.filter((x) => x.result?.toLowerCase() === "negative");

      const labels = [` Total`, `Positive`, "Negative"];
      const months = [
        { label: "jan", monthNumber: 1 },
        { label: "feb", monthNumber: 2 },
        { label: "mar", monthNumber: 3 },
        { label: "apr", monthNumber: 4 },
        { label: "may", monthNumber: 5 },
        { label: "jun", monthNumber: 6 },
        { label: "jul", monthNumber: 7 },
        { label: "aug", monthNumber: 8 },
        { label: "sep", monthNumber: 9 },
        { label: "oct", monthNumber: 10 },
        { label: "nov", monthNumber: 11 },
        { label: "dec", monthNumber: 12 },
      ];

      let monthWisePositiveData = [];
      let monthWiseNegativeData = [];
      let monthWiseTotalData = [];
      months?.map((x, index) => {
        let totalRecordsOfMonth = data?.filter(
          (y) => new Date(y?.created_at)?.getMonth() + 1 === x.monthNumber
        )?.length;
        let negativeRecordsOfMonth = data?.filter(
          (y) =>
            y.result?.toLowerCase() === "negative" &&
            new Date(y?.created_at)?.getMonth() + 1 === x.monthNumber
        )?.length;
        let positiveRecordsOfMonth = data?.filter(
          (y) =>
            y.result?.toLowerCase() === "positive" &&
            new Date(y?.created_at)?.getMonth() + 1 === x.monthNumber
        )?.length;

        monthWiseTotalData.push({
          labels: x.label,
          value: totalRecordsOfMonth,
        });

        monthWisePositiveData.push({
          labels: x.label,
          value: positiveRecordsOfMonth,
        });

        monthWiseNegativeData.push({
          labels: x.label,
          value: negativeRecordsOfMonth,
        });
      });
      const datasets = [
        monthWiseTotalData,
        monthWisePositiveData,
        monthWiseNegativeData,
      ];
      if (!Array.isArray(datasets[0])) datasets = [datasets];

      if (!Array.isArray(labels)) labels = [labels];

      let chart = {
        labels: null,
        datasets: [],
      };
      // for each dataset
      if (datasets) {
        for (let i = 0; i < datasets.length; i++) {
          let values = [],
            ticks = [];

          for (let j = 0; j < datasets[i].length; j++) {
            ticks.push(datasets[i][j].labels);
            values.push(datasets[i][j].value);
          }

          chart.labels = ticks;
          chart.datasets.push({
            label: labels[i],
            data: values,
          });
        }
      }

      setVerificationChart(chart);
    }
  };
  async function makeActive(data) {
    try {
      await axios({
        url: `/api/verification/${data.id}`,
        method: "patch",
        data: { active: true },
      });
      context.notification.show(`User activated`, "success", true);
      setReRender(reRender + 1);
    } catch (err) {
      context.handleError(err);
    }
  }
  const makeInActive = async (data) => {
    try {
      await axios({
        url: `/api/verification/${data.id}`,
        method: "patch",
        data: { active: false },
      });
      context.notification.show(`User in-activate`, "success", true);
      setReRender(reRender + 2);
    } catch (err) {
      context.handleError(err);
    }
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "first_name", //access nested data with dot notation
        header: "First Name",
      },
      {
        accessorKey: "created_at",
        header: "Created At",
      },
      {
        accessorKey: "last_name",
        header: "Last Name",
      },
      {
        accessorKey: "email",
        header: "Email",
      },
      {
        accessorKey: "result",
        header: "result",
      },
      {
        accessorFn: (row) => {
          const date = new Date(row.created_at);
          return `${date.getMonth() + 1}-${date.getFullYear()}`;
        },
        id: "month",
        header: "Month",
      },
      {
        accessorFn: (row) => {
          const date = new Date(row.created_at);
          return `${date.getFullYear()}-${
            date.getMonth() + 1
          }-${date.getDate()}`;
        },
        id: "date",
        header: "Date",
      },
      {
        accessorFn: (row) => {
          const type = row.type.split("+").join(", ");
          return type;
        },
        id: "type",
        filterVariant: "multi-select",
        filterSelectOptions: [
          "kyc",
          "kyc, sanctions",
          "kyc, sanctions_monitoring",
          "kyc, adverse_media",
          "kyc, sanctions, adverse_media",
          "sanctions_monitoring",
        ],
        header: "Type",
      },
      {
        id: "has_sanction_results",
        header: "Sanction Results",
        filterVariant: "select",
        filterSelectOptions: ["Yes", "No", "N/A"],
        accessorFn: (row) => {
          if (!row.type.includes("sanctions")) {
            return "N/A";
          }
          if (row?.sanctions_results?.total_hits > 0) {
            return "Yes";
          } else {
            return "No";
          }
        },
      },
    ],
    []
  );

  const csvConfig = mkConfig({
    fieldSeparator: ",",
    decimalSeparator: ".",
    useKeysAsHeaders: false,
    columnHeaders: [
      "id",
      "first_name",
      "last_name",
      "type",
      "result",
      "created_at",
      "email",
      "updated_at",
    ],
  });

  const handleExportData = () => {
    console.log("in ex", verifications?.data?.verification);
    // csvExporter.generateCsv(verifications?.data?.verification);
    const csv = generateCsv(csvConfig)(
      verifications?.data?.verification.map((v) => {
        return {
          id: v.id,
          first_name: v.first_name,
          last_name: v.last_name,
          type: v.type,
          result: v.result,
          created_at: v.created_at,
          email: v.email,
          updated_at: v.updated_at,
        };
      })
    );
    download(csvConfig)(csv);
  };

  console.log("veri", verifications.data);
  return (
    <Animate type="pop">
      <Message
        buttonText="Refer a friend and get $50 off your next bill"
        buttonLink="/refer"
        noIcon={true}
      />

      {/* <Grid cols='4'>
        // <Stat
        //   loading={ stats?.loading }
        //   value={ stats?.data?.users }
        //   label='users'
        //   icon='users'
        // />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.active }
          label='active'
          icon='check'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.churned }
          label='churned'
          icon='refresh-cw'
        />
        <Stat
          loading={ stats?.loading }
          value={ stats?.data?.latest }
          label='this month'
          icon='calendar'
          change='5%'
        />
      </Grid> */}

      {/* <Card name='revenues' title='Revenue'>
        <Chart
          type='bar'
          legend
          loading={ revenueChart.loading }
          data={ revenueChart.data }
          color={['red', 'blue']}
        />
      </Card> */}

      <Card name="Verifications" title="Verification">
        <Chart
          type="bar"
          legend
          loading={verificationChart.loading}
          data={verificationChart}
          color={["blue", "green", "red"]}
        />
      </Card>

      {/* <Grid cols="0">
        <Card title="Goals" loading={progress.loading}>
          {progress?.data?.map((item) => {
            return (
              <ProgressBar
                key={item.label}
                label={item.label}
                progress={item.value}
              />
            );
          })}
        </Card> */}
      {/* <Card title='User Types'>
          <Chart
            type='pie'
            legend={ true }
            data={ userChart.data }
            loading={ userChart.loading }
            color='purple'
          />
        </Card> */}
      {/* </Grid> */}

      <Card title="Verifications" last>
        {/* <Table
          key={reRender}
          search
          data={verifications.data?.verification?.slice(offset, limit + offset)}
          loading={verifications.loading}
          show={[
            "first_name",
            "last_name",
            "email",
            "result",
            "created_at",
            "active",
          ]}
          actions={{ active: makeActive, inactive: makeInActive, view: { url: 'verification', col: 'id' } }}
          badge={{ col: "plan", color: "blue" }}
        ></Table>
        <div className="pagination">
          <Paginate
            total={verifications.data?.verification?.length}
            limit={limit}
            offset={offset}
            onChange={(e) => setOffset(e)}
          />
        </div> */}
        {verifications?.data?.verification && (
          // <p>hi</p>
          <>
            {console.log("in here", verifications?.data?.verification)}
            <MaterialReactTable
              title="Verifications"
              // theme={theme}
              data={verifications?.data?.verification}
              columns={columns}
              enableRowActions
              enableGrouping
              initialState={{
                columnVisibility: {
                  month: false,
                  created_at: false,
                  type: false,
                  has_sanction_results: false,
                },
                sorting: [{ id: "created_at", desc: true }],
              }}
              positionActionsColumn="last"
              renderRowActions={(row, index) => [
                <Button
                  action={(row) => {
                    console.info("View", row);
                    navigate("/verification/" + row.row.original.id);
                  }}
                  params={row}
                  text="View"
                />,
              ]}
              positionToolbarAlertBanner="bottom"
              renderTopToolbarCustomActions={({ table }) => (
                <Box
                  sx={{
                    display: "flex",
                    gap: "1rem",
                    p: "0.5rem",
                    flexWrap: "wrap",
                    align: "right",
                  }}
                >
                  <Button
                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                    action={handleExportData}
                    text=""
                    icon="download"
                    small
                    key="export"
                  />
                </Box>
              )}
            />
          </>
        )}
      </Card>

      <Feedback />
    </Animate>
  );
}
