import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link, Loader } from "components/lib";
import Collapsible from "react-collapsible";
import "./landing.scss";
import { DirectVerification } from "views/verification/direct-verification";
import imgStep1 from "../../assets/hubspot/step_1.png";
import imgStep2 from "../../assets/hubspot/step_2.png";
import imgStep3 from "../../assets/hubspot/step_3.png";
import imgStep4 from "../../assets/hubspot/step_4.png";
import xeroImgStep1 from "../../assets/xero/step1.png";
import xeroImgStep2 from "../../assets/xero/step2.png";
import xeroImgStep3 from "../../assets/xero/step3.png";
import xeroImgStep4 from "../../assets/xero/step4.png";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

export function LandingView() {
  return (
    <div className="landing-container">
      <div className="carousel">
        {/* <Collapsible trigger="Hubspot Verification"> */}
        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>HubSpot Verification</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div className="demo-text">
                <h4 className="mt-4">
                  Please trigger a verification on your HubSpot console
                </h4>
                <Link url="https://app.hubspot.com/" text="HubSpot" />
                <br />
              </div>

              <div>
                <br />

                <h1>Steps to to do your first KYC</h1>
                <br />
                <h2>Step 1: Open a HubSpot contact</h2>
                <br />
                <p>Choose a contact you can test KYC with - e.g. yourself</p>
                <br />
                <img src={imgStep1} width="500" height="600" />
                <br />
                <br />
                <h2>Step 2: Click &ldquo;View All Properties&rdquo;</h2>
                <p>
                  You may have to scroll down the left panel to find this
                  button.
                </p>
                <br />
                <img src={imgStep2} />
                <br />
                <br />
                <h2>Step 3: Start KYC Command</h2>
                <p>
                  Completing this step will send a unique link via email to your
                  chosen contact.
                </p>
                <br />
                <ol start="1" type="a">
                  <li>Search &ldquo;KYC&rdquo; property.</li>
                </ol>
                <ol start="2" type="a">
                  <li>
                    Select &ldquo;Start&rdquo; from &ldquo;KYC Command&rdquo;
                    drop down.
                  </li>
                </ol>
                <ol start="3" type="a">
                  <li>Save the change.</li>
                </ol>
                <br />
                <img src={imgStep3} />
                <br />
                <br />
                <h2>Step 4: Track KYC results</h2>
                <p>
                  The current status and completed KYC report is noted under
                  &ldquo;Activity&rdquo; in the HubSpot contact.
                </p>
                <br />
                <img src={imgStep4} />
                <br />
                <br />
              </div>
            </AccordionItemPanel>
          </AccordionItem>

          {/* </Collapsible> */}

          {/* <Collapsible trigger="Direct Verification"> */}
          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>Direct Verification</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <br />
              <DirectVerification />
              {/* </Collapsible> */}
            </AccordionItemPanel>
          </AccordionItem>

          <AccordionItem>
            <AccordionItemHeading>
              <AccordionItemButton>XPM Verification</AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <div>
                <br />
                <h1>Getting Started with XPM and IdentityCheck</h1>
                <br />
                <h2>
                  Step 1: Head to integrations page:{" "}
                  <Link url="/account/integrations" text="Integrations" />
                </h2>
                <br />
                <h2>Step 2: Click &ldquo;Install&rdquo;</h2>
                <br />
                <img src={xeroImgStep1} />
                <br />
                <br />
                <h2>Step 3: Authorize IdentityCheck to manage XPM Clients</h2>
                <br />
                <img src={xeroImgStep2} />
                <br />
                <br />
                <h2>Step 4: Select XPM tenant and enable the integration</h2>
                <br />
                <br />
                <br />
                <h2>
                  Step 5:{" "}
                  <a href="https://stackgo.io/identitycheck/xpm/">
                    Follow this blog post to trigger your first check
                  </a>
                </h2>
                <br />
              </div>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </div>
  );
}
