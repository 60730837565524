import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  Row,
  Button,
  useAPI,
  Grid,
  ClassHelper,
  ViewContext,
  useLocation,
  Loader,
} from "components/lib";
import { Form, Message } from "components/lib";
import Axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaste } from "@fortawesome/free-solid-svg-icons";
import Style from "../../components/form/input/input.tailwind.js";
import MaterialReactTable from "material-react-table";

export function DirectVerification(props) {
  const [directVerificationResponse, setDirectVerificationResponse] = useState(
    {}
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [triggeredBy, setTriggeredBy] = useState("direct");
  const [contact_id, setContact_id] = useState("");
  const [refreshForm, setRefreshForm] = useState(false);
  const [searchTerm, setSeachTerm] = useState("");
  const xeroConnected = useAPI("/api/integration/xero/connected");
  const pipeDriveConnected = useAPI("/api/integration/pipedrive/connected");
  const [pipedrivePersons, setPipedrivePersons] = useState([]);
  const [pipedriveSearchTerm, setPipedriveSearchTerm] = useState("");
  const [pipedriveLoading, setPipedriveLoading] = useState(false);
  // const xeroConnections = useAPI("/api/integration/xero/connections");
  const [xeroConnections, setXeroConnections] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState("");
  const [xeroContacts, setXeroContacts] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});

  const copy = <FontAwesomeIcon icon={faPaste} />;
  const viewContext = useContext(ViewContext);
  const [refreshCount, setRefreshCount] = useState(0);
  const location = useLocation();

  const textStyle = ClassHelper(Style, {
    textbox: true,
    className: props.className,
    error: props.valid === false,
    success: props.valid === true,
    warning: props.warning,
  });

  const columns = useMemo(
    () => [
      {
        accessorKey: "Name", //access nested data with dot notation
        header: "Name",
      },
      {
        accessorKey: "EmailAddress",
        header: "Email",
      },
      {
        accessorKey: "ContactId",
        header: "ContactId",
        hidden: true,
      },
      {
        accessorKey: "triggered_by",
        header: "triggered_by",
        hidden: true,
      },
    ],
    []
  );

  const pipedriveColumns = useMemo(
    () => [
      {
        accessorKey: "item.name", //access nested data with dot notation
        header: "Name",
      },
      {
        accessorKey: "item.primary_email",
        header: "Email",
      },
      {
        accessorKey: "item.id",
        header: "Contact Id",
        hidden: true,
      },
      {
        accessorKey: "triggered_by",
        header: "triggered_by",
        hidden: true,
        default: "pipedrive",
      },
    ],
    []
  );

  const searchView = async () => {
    console.log("in here");
    // add text to firstName element
    // document.getElementsByName("firstName").value = "John";
    const type =
      xeroConnections.filter((x) => x.tenantId === selectedAccount)[0]
        .tenantType || "";
    const xeroContactsResponse = await Axios.get(
      "/api/integration/xero/contacts?searchTerm=" +
        searchTerm +
        "&tenantId=" +
        selectedAccount +
        "&tenantType=" +
        type
    );
    console.log("xeroContactsResponse: ", xeroContactsResponse);
    setXeroContacts(xeroContactsResponse?.data?.data?.Contacts);

    // setRefreshForm(true);
  };

  const updateForm = () => {
    // console.log("in updateForm");
    // add text to firstName element
    // document.getElementsByName("firstName").value = "John";
    // console.log("selectedRow: ", selectedRow);
    setFirstName(
      selectedRow?.FirstName || selectedRow?.Name.split(" ")[0] || ""
    );
    setLastName(
      selectedRow?.LastName ||
        selectedRow?.Name.split(" ").slice(1).join(" ") ||
        ""
    );
    setEmail(selectedRow?.EmailAddress);
    setContact_id(selectedRow?.ContactId);
    setTriggeredBy(selectedRow?.triggered_by || "direct");
  };

  const updatePipedriveForm = () => {
    console.log("in updatePipedriveForm", selectedRow);
    setFirstName(selectedRow?.item?.name.split(" ")[0] || "");
    setLastName(selectedRow?.item?.name.split(" ").slice(1).join(" ") || "");
    setEmail(selectedRow?.item?.primary_email);
    setContact_id(selectedRow?.item?.id);
    setTriggeredBy(selectedRow?.triggered_by || "pipedrive");
  };

  // useEffect(() => {
  //   if (xeroConnections?.data?.length > 0) {
  //     setSelectedAccount(xeroConnections?.[0]?.tenantId);
  //   }
  // }, [xeroConnections]);

  useEffect(() => {
    // console.log("refreshForm: ", refreshForm);
    if (refreshForm) {
      if (selectedRow.item) {
        updatePipedriveForm();
      } else {
        updateForm();
      }
      setRefreshForm(false);
    }
  }, [refreshForm]);

  useEffect(async () => {
    if (!xeroConnected?.data) {
      return;
    }
    const xeroConnectionsResponse = await Axios.get(
      "/api/integration/xero/connections"
    );
    console.log("xeroConnectionsResponse: ", xeroConnectionsResponse);
    setXeroConnections(xeroConnectionsResponse?.data?.data);
    setSelectedAccount(xeroConnectionsResponse?.data?.data?.[0]?.tenantId);
  }, [xeroConnected]);

  useEffect(() => {
    // was an error message passed from the server router?
    const qs = location.search;

    if (qs.includes("error") && refreshCount < 1) {
      setRefreshCount(refreshCount + 1);
      const msg = decodeURIComponent(qs.substring(qs.indexOf("?error=") + 7));
      viewContext.notification.show(msg, "error");
    }
  }, [location.search, viewContext.notification, xeroConnected]);

  return (
    <div>
      {xeroConnections?.length > 0 && (
        <Grid>
          <Row>
            <h3>Find Xero Contacts</h3>
            {xeroConnections?.length > 0 && (
              <>
                {console.log("xeroConnections: ", xeroConnections)}
                <Form
                  data={{
                    account: {
                      label: "Account",
                      type: "select",
                      options: xeroConnections?.map((connection) => {
                        return {
                          value: connection.tenantId,
                          label: connection.tenantName,
                        };
                      }),
                      required: true,
                      default: xeroConnections?.[0]?.tenantId,
                      errorMessage: "Please select an account.",
                    },
                  }}
                  updateOnChange
                  onChange={(data) => {
                    console.log("data: ", data);
                    setSelectedAccount(data.value);
                  }}
                />
              </>
            )}
            <br />
            <span>
              <input
                type="text"
                className={textStyle}
                placeholder="Enter your xero contact's name or email or just search"
                onChange={(e) => {
                  setSeachTerm(e.target.value);
                }}
              />
              <br />
              <Button text="Search" action={searchView} small />
            </span>

            <br />
            <br />

            {xeroContacts?.length > 0 && (
              <MaterialReactTable
                columns={columns}
                data={xeroContacts}
                enablePagination={true}
                enableRowActions
                positionActionsColumn="last"
                initialState={{
                  columnVisibility: { ContactId: false, triggered_by: false },
                }}
                renderRowActions={(row, index) => [
                  <Button
                    action={(row) => {
                      console.info("Fill", row);
                      // navigate("/verification/" + row.row.original.id);
                      setSelectedRow(row.row.original);
                      setRefreshForm(true);
                    }}
                    params={row}
                    text="Fill"
                  />,
                ]}
              />
            )}
          </Row>
        </Grid>
      )}
      {pipeDriveConnected?.data && (
        <Grid>
          <Row>
            <h3>Find Pipedrive Contacts</h3>
            <div>
              <input
                type="text"
                label="Search Term"
                className={textStyle}
                placeholder="Enter your Pipedrive contact person's name or email or other properties. Minimum 2 characters."
                required
                minLength={2}
                onChange={(e) => setPipedriveSearchTerm(e.target.value)}
              />
              <br />
              <Button
                text="Search"
                action={async () => {
                  setPipedriveLoading(true);
                  const response = await Axios.get(
                    "/api/integration/pipedrive/search?q=" + pipedriveSearchTerm
                  );
                  console.log("response: ", response);
                  setPipedrivePersons(response?.data?.data?.items || []);
                  setPipedriveLoading(false);
                }}
              />

              <br />
              <br />

              {pipedriveLoading && <Loader />}

              {pipedrivePersons?.length > 0 && (
                <MaterialReactTable
                  columns={pipedriveColumns}
                  data={pipedrivePersons}
                  enablePagination={true}
                  enableRowActions
                  positionActionsColumn="last"
                  initialState={{
                    columnVisibility: { "item.id": false, triggered_by: false },
                  }}
                  renderRowActions={(row, index) => [
                    <Button
                      action={(row) => {
                        console.info("Fill", row);
                        setSelectedRow(row.row.original);
                        setRefreshForm(true);
                      }}
                      params={row}
                      text="Fill"
                    />,
                  ]}
                />
              )}
            </div>
          </Row>
        </Grid>
      )}
      <Row>
        {!refreshForm && (
          <Form
            data={{
              firstName: {
                label: "First name",
                type: "text",
                required: true,
                errorMessage: "Please enter first name.",
                value: firstName,
              },
              lastName: {
                label: "Last name",
                type: "text",
                required: true,
                errorMessage: "Please enter last name.",
                value: lastName,
              },
              email: {
                label: "Email",
                type: "email",
                required: true,
                errorMessage: "Please enter email address.",
                value: email,
              },
              emailUser: {
                label: "Email User",
                type: "radio",
                options: ["Yes", "No"],
                required: true,
                errorMessage: "Please choose to email user or not.",
              },
              type: {
                type: "hidden",
                value: "kyc",
              },
              triggeredBy: {
                type: "hidden",
                value: triggeredBy,
              },
              active: {
                type: "hidden",
                value: true,
              },
              contactId: {
                type: "hidden",
                value: contact_id,
              },
            }}
            method="POST"
            url="/api/direct-verification"
            callback={(res) => {
              console.log("response:::: ", res.data);
              setDirectVerificationResponse(res.data);
            }}
            buttonText="Verify"
          />
        )}

        {directVerificationResponse.data !== null &&
          directVerificationResponse.data !== undefined && (
            <div>
              <br />
              <br />
              <p>
                {directVerificationResponse.data.idUrl} {"      "}
                <span
                  className="sg-icon-copy"
                  onClick={() => {
                    navigator.clipboard.writeText(
                      directVerificationResponse.data.idUrl
                    );
                  }}
                >
                  {copy}
                </span>
              </p>
            </div>
          )}
      </Row>
    </div>
  );
}
